<template>
  <page-content>
    <page-title
      heading="Admin E-learning"
    ></page-title>

    <!-- <banner-image-home></banner-image-home> -->

    <!-- <div>
      <video-home></video-home>
    </div> -->

    <!-- <b-row align-h="center">
      <b-col md="6">
        <a class="card-img-wrapper rounded" href="javascript:void(0)">
          <div class="img-wrapper-overlay">
            <div class="overlay-btn-wrapper">
              <b-button
                size="sm"
                pill
                variant="outline-secondary"
                class="
                  border-0
                  shadow-none
                  pl-2
                  pr-2
                  mx-auto
                  d-flex
                  align-items-center
                "
              >
                <font-awesome-icon icon="play-circle" class="font-size-lg" />
              </b-button>
            </div>
          </div>

          <b-embed type="video" controls>
            <source
              src="https://d10a8qjc50njp0.cloudfront.net/videos/HBD+D.A.R.E.mov"
              type="video/mp4"
            />
          </b-embed>
        </a>

        <b-embed type="video" controls class="mt-4">
          <source
            src="https://d10a8qjc50njp0.cloudfront.net/videos/HBD+D.A.R.E.mov"
            type="video/mp4"
          />
        </b-embed>
      </b-col>
      <b-col md="6" class="mt-sm-3 mt-md-0">
        <a class="card-img-wrapper rounded" href="javascript:void(0)">
          <div class="img-wrapper-overlay">
            <div class="overlay-btn-wrapper">
              <b-button
                size="sm"
                pill
                variant="outline-secondary"
                class="
                  border-0
                  shadow-none
                  pl-2
                  pr-2
                  mx-auto
                  d-flex
                  align-items-center
                "
              >
                <font-awesome-icon icon="play-circle" class="font-size-lg" />
              </b-button>
            </div>
          </div>

          <b-embed
            src="https://www.youtube.com/embed/vmd2KaTElDE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </b-embed>
        </a>
      </b-col>
    </b-row> -->

    <!-- <h3 class="mt-4">ข่าวประชาสัมพันธ์</h3>

    <b-container class="container___width-override">
      <news></news>
    </b-container> -->
  </page-content>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import VideoHome from "../components/carousel/VideoHome";
// import News from "../components/carousel/News";

export default {
  components: {
    PageContent,
    PageTitle,
    // BannerImageHome,
    // News,
    // VideoHome,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
